import React from 'react';
import { BsTwitter, BsInstagram, BsDiscord, BsWhatsapp } from 'react-icons/bs';
import { FaYoutube, FaLinkedinIn, FaSpotify, FaVk, FaVimeoV, FaPatreon, FaMedium, FaPinterestP, FaTumblr } from 'react-icons/fa';

import { images }  from '../constants';

const SocialMedia = () => {
  return (
    <div className='app__social'>
        <div>
        <a href='https://wa.me/message/F6YZDMW4H7IDF1'><BsWhatsapp /></a>
        </div>
        <div>
        <a href='https://vk.com/munuandmoti'><FaVk /></a>
        </div>
        <div>
        <a href='https://twitter.com/munuandmoti'><BsTwitter /></a>
        </div>
        <div>
        <a href='https://www.tumblr.com/munuandmoti'><FaTumblr /></a>
        </div>
        <div>
        <a href='https://medium.com/@munuandmoti'><FaMedium /></a>
        </div>
        <div>
        <a href='https://www.instagram.com/munuandmoti/'><BsInstagram /></a>
        </div>
        <div>
        <a href='https://in.pinterest.com/munuandmoti/'><FaPinterestP /></a>
        </div>
        <div>
        <a href='https://www.youtube.com/channel/UCWPInqN8TMvXAWCUdCWExDg'><FaYoutube /></a>
        </div>
        <div>
        <a href='https://vimeo.com/munuandmoti'><FaVimeoV /></a>
        </div>
        <div>
        <a href='https://rumble.com/c/c-1995432'><img src={images.rumble} alt=''/></a>
        </div>
        <div>
        <a href='https://patreon.com/user?u=81116952'><FaPatreon /></a>
        </div>
        <div>
        <a href='https://www.linkedin.com/in/munuandmoti'><FaLinkedinIn /></a>
        </div>
        <div>
        <a href='https://discord.com/users/MUNU & MOTI™#3486'><BsDiscord /></a>
        </div>
        <div>
        <a href='https://open.spotify.com/user/31kgc4lhw2wtcsolw64nwueigsaa'><FaSpotify /></a>
        </div>
  </div>
  )
}

export default SocialMedia
