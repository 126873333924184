import email from '../assets/email.png';
import mobile from '../assets/mobile.png';

import react from '../assets/react.png';
import solidity from '../assets/solidity.png';
import rust from '../assets/rust.png';
import rumble from '../assets/rumble.png';
import form from '../assets/form.png';


import profile from '../assets/profile.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';

import spotify from '../assets/spotify.png';

export default {
  email,
  mobile,

  react,
  solidity,
  rust,

  profile,
  circle,
  logo,
  
  spotify,
  rumble,
  form,
};
