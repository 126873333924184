import React from 'react';
import './Mnmpowered.scss';
import PriceList from '../../assets/PriceList.pdf'


const Mnmpowered = () => {
    return(
      <>
      <div className='set_start'>
    <p className="p-text"><a href={PriceList}>PRICE LIST</a> | <a href='https://policies.munuandmoti.tech/#Terms%20&%20Conditions'>TERMS & CONDITIONS</a> | <a href='https://policies.munuandmoti.tech/#Data%20&%20Privacy'>DATA & PRIVACY POLICY</a> | <a href='https://policies.munuandmoti.tech/#FAQ'>FAQ</a></p>
      </div>
    {/*<div className='set_start'>
    <p className="p-text"><a href='https://policies.munuandmoti.tech/#Terms%20&%20Conditions'>TERMS & CONDITIONS</a></p>
      </div>
      <div className='set_start'>
      <p className="p-text"><a href='https://policies.munuandmoti.tech/#Data%20&%20Privacy'>DATA & PRIVACY POLICY</a></p> 
      </div>
      <div className='set_start'>
      <p className="p-text"><a href='https://policies.munuandmoti.tech/#FAQ'>FAQ</a></p> 
    </div>*/}
      </>
    );
};

export default Mnmpowered;