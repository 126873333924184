import React from 'react';
import './App.scss';

import { About, Footer, Header, Mnmpowered, Skills, Testimonial, Work , Products } from './container';
import { Navbar } from './components';

const App = () => {
  return (
    <div className='app'>
      <Navbar />
      <Header />
      <About />
      <Work />
      <Products />
      <Skills />
      <Testimonial />
      <Footer />
      <Mnmpowered />
    </div>
  )
}

export default App;